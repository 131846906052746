import { createRouter, createWebHistory } from 'vue-router'

function lazyLoad(view){
  return() => import(`@/components/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: lazyLoad('HomePage')
  },
  {
    path: '/articles',
    name: 'sleep-info',
    component: lazyLoad('SleepInfo')
  },
  {
    path: '/download-ios-beta/:z?',
    name: 'download-ios-beta',
    component: lazyLoad('DownloadIosBeta')
  },
  {
    path: '/AQT',
    name: 'aqt',
    component: lazyLoad('AQT')
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: lazyLoad('Leaderboard')
  },
  {
    path: '/about/:view?',
    name: 'about',
    component: lazyLoad('About')
  },
  {
    path: '/sleep-eval',
    name: 'sleep-eval',
    component: lazyLoad('SleepSurvey')
  },
  {
    path: '/sleep-solutions',
    name: 'sleep-solutions',
    component: lazyLoad('SleepSolutions')
  },
  {
    path: '/solutions',
    name: 'buzz',
    component: lazyLoad('Buzz')
  },
  {
    path: '/account',
    name: 'account',
    component: lazyLoad('Account')
  },
  {
    path: '/app',
    name: 'app',
    component: lazyLoad('MobileApp')
  },
  {
    path: '/view-solution/:id',
    name: 'view-solution',
    component: lazyLoad('Product-Page')
  },
  {
    path: '/view-article/:id',
    name: 'view-article',
    component: lazyLoad('Sleep-Info-Item')
  },
  {
    path: '/solution/:id/:name/:view',
    name: 'solution',
    component: lazyLoad('Solution')
  },
  {
    path: '/applink/:type/:uuid',
    name: 'applink',
    component: lazyLoad('Link')
  },
  // {
  //   path: '/applink',
  //   name: 'applink',
  //   component: lazyLoad('Link')
  // },
  {
    path: '/hypnochronbuilder',
    name: 'hypnochronbuilder',
    component: import(`@/components/HypnochronBuilder.vue`)
  }
  ,{
    path: '/:pathMatch(.*)*',
    component: lazyLoad('Link')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async(to)=>{
  let hasMatch = false;
  routes.forEach(r=>{
    if(!hasMatch){
      let routePath = '/' + r.path.split('/')[1];
      hasMatch = routePath.toLocaleLowerCase() == to.path.toLowerCase();
    }
  });
  if(!hasMatch){
    // HAS UUID - DEEPLINK 
    if(to.query && to.query.uuid){
      let type = to.path.split('/')[1];
      to.query['type']= type;
      // to.fullPath = '/applink/' + type + '/' + to.query.uuid;
      // to.path = '/applink';
      // router.replace(to);
    }
    // // NO UUID: redirect to home 
    // else{
    //   to.fullPath = '/app';
    //   to.path = '/app'
    // }
  }
});


export default router
